export function resolveNodesFromResourcesQueryResultForLocale(resourcesQueryResult, locale) {
  const { en, de, nl, fr, it, es, enUS, cs, pl } = resourcesQueryResult
  let nodes

  switch (locale) {
    case 'de':
      nodes = de.nodes
      break
    case 'nl':
      nodes = nl.nodes
      break
    case 'fr':
      nodes = fr.nodes
      break
    case 'it':
      nodes = it.nodes
      break
    case 'es':
      nodes = es.nodes
      break
    case 'en-US':
      nodes = enUS.nodes
      break
    case 'cs':
      nodes = cs.nodes
      break
    case 'pl':
      nodes = pl.nodes
      break
    default:
      nodes = en.nodes
  }

  return nodes
}

export function resolveSlugsFromResourcesQueryResultForLocale(resourcesQueryResult, locale) {
  const nodes = resolveNodesFromResourcesQueryResultForLocale(resourcesQueryResult, locale)
  return nodes[0].slug
}

export function resolveMicrocopyFromResourcesQueryResultForLocale(resourcesQueryResult, locale) {
  const nodes = resolveNodesFromResourcesQueryResultForLocale(resourcesQueryResult, locale)
  return resolveMicrocopyForLocale(nodes, locale)
}

export function resolveMicrocopyForLocale(nodes, locale) {
  let microcopy
  for (let i = 0; i < nodes.length; i++) {
    let node = nodes[i]

    if (node.node_locale === locale) {
      microcopy = node.microcopy
    }
  }

  if (microcopy) {
    return microcopy.reduce((a, x) => ({ ...a, ['' + x.key + '']: x.value }), {})
  }

  return undefined
}
