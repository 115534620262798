import { useStaticQuery, graphql } from 'gatsby'
import { resolveMicrocopyFromResourcesQueryResultForLocale } from '../../services/microcopyService'

const resourcesQuery = graphql`
  query {
    en: allContentfulMicrocopyContainer(
      filter: { node_locale: { eq: "en" }, key: { eq: "language" } }
    ) {
      nodes {
        ...CoreMicrocopyFields
      }
    }
    de: allContentfulMicrocopyContainer(
      filter: { node_locale: { eq: "de" }, key: { eq: "language" } }
    ) {
      nodes {
        ...CoreMicrocopyFields
      }
    }
    nl: allContentfulMicrocopyContainer(
      filter: { node_locale: { eq: "nl" }, key: { eq: "language" } }
    ) {
      nodes {
        ...CoreMicrocopyFields
      }
    }
    fr: allContentfulMicrocopyContainer(
      filter: { node_locale: { eq: "fr" }, key: { eq: "language" } }
    ) {
      nodes {
        ...CoreMicrocopyFields
      }
    }
    it: allContentfulMicrocopyContainer(
      filter: { node_locale: { eq: "it" }, key: { eq: "language" } }
    ) {
      nodes {
        ...CoreMicrocopyFields
      }
    }
    es: allContentfulMicrocopyContainer(
      filter: { node_locale: { eq: "es" }, key: { eq: "language" } }
    ) {
      nodes {
        ...CoreMicrocopyFields
      }
    }
    enUS: allContentfulMicrocopyContainer(
      filter: { node_locale: { eq: "en-US" }, key: { eq: "language" } }
    ) {
      nodes {
        ...CoreMicrocopyFields
      }
    }
    cs: allContentfulMicrocopyContainer(
      filter: { node_locale: { eq: "cs" }, key: { eq: "language" } }
    ) {
      nodes {
        ...CoreMicrocopyFields
      }
    }
    pl: allContentfulMicrocopyContainer(
      filter: { node_locale: { eq: "pl" }, key: { eq: "language" } }
    ) {
      nodes {
        ...CoreMicrocopyFields
      }
    }
  }
`

export const useLanguageI18n = (locale) => {
  return resolveMicrocopyFromResourcesQueryResultForLocale(useStaticQuery(resourcesQuery), locale)
}
