import getBaseUrl from './baseUrlService'
import { getCookie, xsrfTokenKey } from './cookieService'

const xsrfType = 0 // 0 = per session, 1 = per request

export const BaseRoute = '/v2/'
export const BaseApiRoute = '/v2/api/'

export function getApiBaseUrl() {
  return new Promise((resolve, reject) => {
    if (process.env.GATSBY_DEV_OVERRIDE_BACKEND_URL === undefined) {
      getBaseUrl()
        .then((url) => resolve(url))
        .catch((error) => reject(400))
    } else {
      resolve(process.env.GATSBY_DEV_OVERRIDE_BACKEND_URL)
    }
  })
}

export function jsonApiPostRequest(urlPath, bodyObj, isBlob = false) {
  if (xsrfType === 1) {
    return jsonApiPostRequestWithXSRFPerRequest(urlPath, bodyObj, isBlob)
  } else {
    return jsonApiPostRequestWithXSRFPerSession(urlPath, bodyObj, isBlob)
  }
}

function createDefaultHeaders(xsrf) {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-XSRF-TOKEN': xsrf,
  }
}

/* Per Request XSRF - Begin */
let getXSRFPerRequestPromise = undefined

function jsonApiPostRequestWithXSRFPerRequest(urlPath, bodyObj, isBlob) {
  return new Promise((resolve, reject) => {
    getApiBaseUrl()
      .then((baseUrl) => {
        getXSRFPerRequest(baseUrl)
          .then((xsrf) => {
            const url = `${baseUrl}${urlPath}`
            fetch(url, {
              credentials: 'include',
              headers: createDefaultHeaders(xsrf),
              method: 'POST',
              body: JSON.stringify(bodyObj),
            })
              .then((response) => {
                switch (response.status) {
                  case 200:
                    if (isBlob === true) {
                      response.blob().then((blob) => resolve(blob))
                    } else {
                      response.json().then((data) => resolve(data))
                    }
                    break
                  case 204:
                    resolve(null)
                    break
                  default:
                    response
                      .json()
                      .then((errorData) => {
                        const data = JSON.parse(errorData)
                        reject({ ...data, status: response.status, requestId: bodyObj.requestId })
                      })
                      .catch(() =>
                        reject({ status: response.status, requestId: bodyObj.requestId })
                      )

                    break
                }
              })
              .catch((error) => reject({ error: error, requestId: bodyObj.requestId }))
          })
          .catch((error) => reject({ error: error, requestId: bodyObj.requestId }))
      })
      .catch((error) => reject({ error: error, requestId: bodyObj.requestId }))
  })
}

function getXSRFPerRequest(baseUrl) {
  if (getXSRFPerRequestPromise === undefined) {
    getXSRFPerRequestPromise = new Promise((resolve, reject) => {
      const url = `${baseUrl}${BaseApiRoute}secure`
      fetch(url, { credentials: 'include' })
        .then((response) => {
          switch (response.status) {
            case 200:
              response.json().then((data) => {
                const xsrf = getCookie(xsrfTokenKey)
                if (xsrf === undefined) {
                  reject('failed to get xsrf')
                } else {
                  resolve(xsrf)
                }
              })
              break
            default:
              reject(response.status)
              break
          }
        })
        .catch((error) => reject(error))
        .finally(() => {
          getXSRFPerRequestPromise = undefined
        })
    })
  }

  return getXSRFPerRequestPromise
}
/* Per Request XSRF - End */

/* Per Session XSRF - Begin */
function jsonApiPostRequestWithXSRFPerSession(urlPath, bodyObj, isBlob) {
  return new Promise((resolve, reject) => {
    jsonApiPostRequestWithSessionXSRF(urlPath, bodyObj, false, isBlob)
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        if (error.status === 400) {
          // try again, this time request a new xsrf token
          jsonApiPostRequestWithSessionXSRF(urlPath, bodyObj, true, isBlob)
            .then((data) => resolve(data))
            .catch((error) => reject(error))
        } else {
          reject(error)
        }
      })
  })
}

function jsonApiPostRequestWithSessionXSRF(urlPath, bodyObj, forceNewXSRF, isBlob) {
  return new Promise((resolve, reject) => {
    getApiBaseUrl()
      .then((baseUrl) => {
        getXSRFPerSession(baseUrl, forceNewXSRF)
          .then((xsrf) => {
            const url = `${baseUrl}${urlPath}`
            fetch(url, {
              credentials: 'include',
              headers: createDefaultHeaders(xsrf),
              method: 'POST',
              body: JSON.stringify(bodyObj),
            })
              .then((response) => {
                switch (response.status) {
                  case 200:
                    if (isBlob === true) {
                      response.blob().then((blob) => resolve(blob))
                    } else {
                      response.json().then((data) => resolve(data))
                    }
                    break
                  case 204:
                    resolve(null)
                    break
                  default:
                    response
                      .json()
                      .then((errorData) => {
                        const data = JSON.parse(errorData)
                        reject({ ...data, status: response.status, requestId: bodyObj.requestId })
                      })
                      .catch(() =>
                        reject({ status: response.status, requestId: bodyObj.requestId })
                      )

                    break
                }
              })
              .catch((error) => reject({ error: error, requestId: bodyObj.requestId }))
          })
          .catch((error) => reject({ error: error, requestId: bodyObj.requestId }))
      })
      .catch((error) => reject({ error: error, requestId: bodyObj.requestId }))
  })
}

function getXSRFPerSession(baseUrl, forceNewXSRF) {
  return new Promise((resolve, reject) => {
    const url = `${baseUrl}${BaseApiRoute}secure`
    const existingXSRF = getCookie(xsrfTokenKey)

    if (existingXSRF === undefined || forceNewXSRF === true) {
      fetch(url, { credentials: 'include' })
        .then((response) => {
          switch (response.status) {
            case 200:
              response.json().then((data) => {
                const xsrf = getCookie(xsrfTokenKey)
                if (xsrf === undefined) {
                  reject('failed to get xsrf')
                } else {
                  resolve(xsrf)
                }
              })
              break
            default:
              reject(response.status)
              break
          }
        })
        .catch((error) => reject(error))
    } else {
      resolve(existingXSRF)
    }
  })
}
/* Per Session XSRF - End */
