import { useStaticQuery, graphql } from 'gatsby'
import { supportedLocales } from '../services/constantsService'
import { useLanguageI18n } from './i18n/useLanguageI18n'
import { convertToMicrocopyKey } from '../services/i18nService'

const resourcesQuery = graphql`
  query {
    allContentfulNavigationElement(filter: { navigation_id: { eq: "Home" } }) {
      nodes {
        ...CoreNavigationFields
      }
    }
  }
`

export const useLanguages = (locale) => {
  const locales = JSON.parse(supportedLocales)
  const microcopy = useLanguageI18n(locale)
  const { allContentfulNavigationElement } = useStaticQuery(resourcesQuery)
  let availableLocales = []

  for (
    let index = 0, length = allContentfulNavigationElement.nodes.length;
    index < length;
    index++
  ) {
    let currentNode = allContentfulNavigationElement.nodes[index]

    if (locales.includes(currentNode.node_locale) && microcopy) {
      availableLocales.push({
        slug: currentNode.slug,
        title: microcopy[`language.${convertToMicrocopyKey(currentNode.slug)}`]?.value,
      })
    }
  }

  return availableLocales
}

export const useOrderedLanguages = (locale) => {
  const languages = useLanguages(locale)

  let ordered = []
  let english = languages.find((element) => element.slug === 'en')
  ordered.push(english)

  languages.sort((a, b) =>
    convertLanguageText(a.title) > convertLanguageText(b.title)
      ? 1
      : convertLanguageText(b.title) > convertLanguageText(a.title)
      ? -1
      : 0
  )

  languages.forEach((element) => {
    if (ordered.some((searchElement) => searchElement.slug === element.slug)) {
    } else {
      ordered.push(element)
    }
  })

  return ordered
}

function convertLanguageText(title) {
  title = title.replaceAll('Č', 'C')
  return title
}
